.container_form {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5% 5px
}
.border {
  margin-right: 3%;
  margin-left: 3%;
  border: solid 10px transparent;
  border-radius: 30px;
  background:
      linear-gradient(#ffffff, #fff0cb) padding-box,
      linear-gradient(#ffb700, #feb7c8) border-box;
  align-items: center;
}
.conten{
  display: 'flex';
  width: 70%;
  flex-direction: 'column' 
}

.form {
  display: flex;
  flex-direction: column;
  width: 600px;
  /* height: 500px; */
  /* margin-top: 5px; */
  justify-content: center;
}
textarea {
  resize: none
}
input {
  width: 100%;
  height: 50px;
}
.p {
  /* width: 700px; */
  text-align: center;
  margin: 20px auto
}